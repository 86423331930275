import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    Y_value: 0,
    login_modal: false,
    is_login: false,
    user: {
        name: '',
        email: '',
        profile: ''
    }
}

export const config = createSlice({
    name: 'Config',
    initialState,
    reducers: {
        SetYvalue: (state, action) => {
            state.Y_value = action.payload
        },
        SetLoginModal: (state, action) => {
            state.login_modal = action.payload
        },
        checklogin: (state, action) => {
            state.is_login = action?.payload.is_login
            state.user = {
                name: action?.payload?.name,
                email: action?.payload?.email,
                profile: action?.payload?.profile
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { SetYvalue, SetLoginModal, checklogin } = config.actions

export default config.reducer