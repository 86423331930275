import React from "react";
import { useSelector } from "react-redux";

const Filterbtn = ({ ActiveTab, TabHandler }) => {
  const Y_value = useSelector((state) => state.config.Y_value);
  return (
    <div
      className={`flex w-[100%] min-[800px]:fixed  min-[800px]:top  min-[800px]:top-[${
        Y_value < 250 ? "148px" : "100px"
      }]  min-[800px]:left-[calc(24%)] search-bar  z-[2] my-2 bg-[#fdefdf] max-[800px]:flex max-[800px]:flex-wrap max-[800px]:justify-center max-[800px]:my-5`}
    >
      {["Videos", "Games", "Liveworksheets", "Physical_worksheets"].map(
        (item, index) => {
          return (
            <button
              key={index}
              className={`py-2 px-5 border-2 mx-2  font-semibold rounded-xl border-DLblue ${
                ActiveTab == item
                  ? "text-DLlight bg-DLblue"
                  : "text-DLblue bg-DLlight"
              } max-[800px]:text-[12px] max-[800px]:py-1 max-[800px]:px-2 max-[800px]:m-1`}
              onClick={() => TabHandler(item)}
            >
              {item?.replace("_", " ")}
            </button>
          );
        }
      )}
    </div>
  );
};

export default Filterbtn;
