import React, { useEffect, useState } from "react";
import Header from "./Header";
import Banner from "../../../Assest/images/games/blob1.png";
import Banner1 from "../../../Assest/images/games/blob.gif";
import Banner2 from "../../../Assest/images/games/Bird.png";
import Banner3 from "../../../Assest/images/games/Bird.png";
import Headertwo from "./Headertwo";
import Headerthree from "./Headerthree";
const Intro = () => {
  const [Active, setActive] = useState(0);
  const [Time, setTime] = useState(5);
  const [Progress, setProgress] = useState(0);
  const [Pause, setPause] = useState(false);

  const BannerData = [
    {
      bg: Banner,
      tag: (
        <Header
          data={{
            id: 1,
            title_1: "Gaming Platform",
            title_2: "for Different Learners",
            description:
              "When you're just starting out, we focus on the foundation. We teach you three essential skills: Reading, Writing, and Arithmetic (which is all about numbers). These are like the building blocks of learning. We also help you improve your ability to see and hear things clearly through fun activities.",
            bg: Banner,
            thumb: Banner1,
          }}
        />
      ),
    },
    {
      bg: Banner3,
      tag: <Headertwo />,
    },
    {
      bg: Banner2,
      tag: <Headerthree />,
    },
    // ,
    // {

    // },
    // {
    //   id: 3,
    //   title_1: "Fostering Success in,",
    //   title_2: "Every Student.",
    //   description:
    //     "Welcome to our inclusive learning community dedicated to helping students overcome reading challenges. We provide resources, strategies, and support to nurture every student's unique potential and foster academic success",
    //   bg: Banner3,
    //   thumb: Banner3,
    // },
  ];

  useEffect(() => {
    if (!Pause) {
      const interval = setInterval(() => {
        if (Time <= 0) {
          setActive(() => (Active == BannerData?.length - 1 ? 0 : Active + 1));
          setTime(5);
          setProgress(0);
          clearInterval(interval);
        } else {
          setTime(Time - 1);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [Time, Pause]);

  useEffect(() => {
    if (!Pause) {
      const interval = setInterval(() => {
        if (Progress >= 100) {
          setProgress(0);
        } else {
          setProgress(Progress + 1);
        }
      }, 5000 / 93);
      return () => {
        clearInterval(interval);
      };
    }
  }, [Progress, Active, Pause]);

  useEffect(() => {
    return () => {
      setProgress(0);
      setTime(5);
      setPause(false);
      setActive(0);
    };
  }, []);
  return (
    <div className="w-[100%] h-[93vh] max-h-[1000px] min-w-[500px] bg-[#ccc] slider-home max-[600px]:min-w-[100%]">
      {BannerData?.map((item, index) => {
        if (Active === index) {
          return (
            <div
              key={index}
              className="slide-div bg-DLblue"
              style={{
                background: `url(${item?.bg})`,
                backgroundSize: "cover",
              }}
            >
              {item?.tag}
              {/* <Header data={item} />
               */}
              {/* <Headertwo /> */}
              {/* <Headerthree /> */}
            </div>
          );
        }
      })}

      <div className="slider-btn ">
        <div>
          {BannerData?.map((item, index) => {
            return (
              <button
                onClick={() => {
                  setActive(index);
                  setTime(5);
                  setProgress(0);
                  setPause(false);
                }}
                key={index}
                className={`slider-btn-item w-[${
                  Active == index ? "50px" : "10px"
                }] h-[10px] mx-2 bg-DLlight rounded-full cursor-pointer overflow-hidden`}
              >
                {Active == index && (
                  <div className={`w-[${Progress}%] h-[10px] bg-[#ccc]`}></div>
                )}
              </button>
            );
          })}
        </div>

        <button
          className="text-DLlight pause-btn "
          onClick={() => setPause(!Pause)}
        >
          {!Pause ? (
            <i className="fa-regular fa-circle-pause"></i>
          ) : (
            <i className="fa-regular fa-circle-play"></i>
          )}
        </button>
      </div>
    </div>
  );
};

export default Intro;
