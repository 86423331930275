import React, { useEffect, useState } from "react";

const Search = ({ Couselist, ActiveLevel, ActiveCourse, LessonHandler }) => {
  const [SearchValue, setSearchValue] = useState("");
  const [SearchList, setSearchList] = useState(Couselist[ActiveLevel]);

  useEffect(() => {
    setSearchList([]);
    setSearchList(() => Couselist[ActiveLevel]);
  }, [ActiveLevel]);

  const SearchHandler = (e) => {
    setSearchValue(e.target.value);
    const Duplicate = Couselist[ActiveLevel]?.filter((item, index) => {
      return item?.concept_name
        ?.toLowerCase()
        ?.includes(e.target.value?.toLowerCase());
    });
    setSearchList(() => Duplicate);
    LessonHandler(null, null);
  };
  return (
    <div className=" w-full  p-2 h-[100%]">
      <input
        type="text"
        placeholder="search..."
        className="border-2 w-full py-2 px-2 mb-5 rounded outline-0"
        value={SearchValue}
        onInput={SearchHandler}
      />

      <div className="max-h-[80%] overflow-y-auto search-item-container">
        {SearchList?.map((item, index) => {
          return (
            <button
              key={item?.id + index}
              className="w-[98%] search-item flex items-center"
              onClick={() => LessonHandler(index, item.id)}
            >
              <i
                className={`fa-solid fa-caret-right text-[26px] mr-2 ${
                  ActiveCourse == index ? "opacity-100" : "opacity-0"
                } `}
              ></i>{" "}
              <p>{item?.concept_name}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Search;
