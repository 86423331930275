import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#659FD2] p-2">
      <div className="flex items-start flex-wrap justify-evenly">
        {/* DOWNLOAD */}
        <div className="flex items-center flex-col m-2 min-w-[300px]">
          <img
            src="https://dlearners.com/assets/img/_Clip%20Group_%206.png"
            alt=""
            className="max-w-[100px] rounded-xl"
          />
          <a
            href="https://apps.apple.com/in/app/dlearners-parent/id1532043292"
            target="_blank"
          >
            <img
              src="https://dlearners.com/assets/images/app%20store.png"
              alt=""
              className="max-w-[140px] my-2"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.screening.dlparent"
            target="_blank"
          >
            <img
              src="https://dlearners.com/assets/images/get-it-on-google-play-badge-logo-8CDE582776-seeklogo.com.png"
              alt=""
              className="max-w-[140px]"
            />
          </a>
        </div>
        {/* Links */}
        <div className="m-2 min-w-[300px]">
          <h3 className="text-DLlight font-bold text-[20px]">Quicklinks</h3>
          <ul className="mt-5">
            <li className="my-1">
              <a
                href="https://dlearners.com/about.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                About Us{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/peopleOfDLearners.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                People of DLearners{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/peopleOfDLearners.html#governing_body"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                Governing Body{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/peopleOfDLearners.html#Promoters"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                Promoters
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/careers.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                Careers
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/corporates.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                For Corporates
              </a>
            </li>
          </ul>
        </div>
        {/* PLATFORM */}
        <div className="m-2 min-w-[300px]">
          <h3 className="text-DLlight font-bold text-[20px]">Platforms</h3>
          <ul className="mt-5">
            <li className="my-1">
              <a
                href="https://dlearners.com/seep.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                Special Educator Engagement Platform{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.com/schools.html"
                className="underline text-DLlight font-semibold text-[15px]"
              >
                {" "}
                Platform for Schools{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.in/Teacher/"
                className="underline text-DLlight font-semibold text-[15px]"
                target="_blank"
              >
                Teachers Portal{" "}
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners.in/Parents_login/"
                className="underline text-DLlight font-semibold text-[15px]"
                target="_blank"
              >
                Parents Portal
              </a>
            </li>
            <li className="my-1">
              <a
                href="https://dlearners-helpdesk.freshdesk.com/support/home"
                className="underline text-DLlight font-semibold text-[15px]"
                target="_blank"
              >
                Raise A Ticket
              </a>
            </li>
          </ul>
        </div>
        {/* Contact US */}
        <div className="max-w-[300px] m-2">
          <h3 className="text-DLlight font-bold text-[20px]">Contact Us</h3>
          <ul className="mt-5 w-[100%]">
            <li className=" my-1">
              <a
                href="https://maps.app.goo.gl/XVrtWCA9p4cxd6rR8"
                target="_blank"
                className="underline text-DLlight font-semibold text-[15px] w-full"
              >
                <i className="fa-solid fa-house mr-2"></i> Celestie Building,{" "}
                <br></br>6, Shree Avenue, Saibaba Nagar, Narayanapuram,
                Pallikaranai, Chennai, Tamil Nadu 600100
              </a>
            </li>
            <li className="my-1">
              <a
                href="tel: 7299125465"
                className="underline text-DLlight font-semibold text-[15px] w-full"
                target="_blank"
              >
                <i className="fa-solid fa-phone  mr-2"></i>7299125465
              </a>
            </li>
            <li className="my-1">
              <a
                href="mailto: support@dlearners.in"
                className="underline text-DLlight font-semibold text-[15px] w-full"
              >
                <i className="fa-solid fa-envelope mr-2"></i>
                support@dlearners.in
              </a>
            </li>

            <li>
              <h3 className="text-DLlight font-bold text-[20px]">
                Follow us on
              </h3>
              <ul className="flex">
                <li className="mx-2">
                  <a
                    href="https://www.instagram.com/dlearners.in/?hl=en"
                    target="_blank"
                    className="underline text-DLlight font-semibold text-[26px] w-full"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://www.youtube.com/channel/UCg6gqV3mWMfDdBlwYUcfNPw/videos?view_as=subscriber"
                    target="_blank"
                    className="underline text-DLlight font-semibold text-[26px] w-full"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://www.linkedin.com/company/different-learners/"
                    target="_blank"
                    className="underline text-DLlight font-semibold text-[26px] w-full"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://www.facebook.com/DLearners.in"
                    target="_blank"
                    className="underline text-DLlight font-semibold text-[26px] w-full"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/* LINKS */}
      <div className="my-5">
        <p className="my-2 text-[16px] text-DLlight text-center">
          &copy; Copyright 2020 - 2030 Blink Research and Service Pvt.Ltd. All
          rights reserved.
        </p>

        <ul className="flex flex-wrap items-start justify-center max-[600px]:justify-start">
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/Privacy-Policy.html"
            >
              Privacy Policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/refund_policy.html"
            >
              Refund Policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/terms-conditions.html"
            >
              Terms and Condition
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/whistle-blower-policy.html"
            >
              Whistle blower policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/non-discrimination-and-equal-opportunities-policy.html"
            >
              Non-Discrimination and equal oppurtunities policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/anti-corruption-policy.html"
            >
              Anti corruption policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/conflict-of-interest-policy.html"
            >
              Conflict of intrest policy
            </a>
          </li>
          <li className="m-2 max-[600px]:w-full">
            <a
              className="text-DLlight underline text-[14px]"
              href="https://dlearners.com/code_of_conduct.html"
            >
              Code of Conduct
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
