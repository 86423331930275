import React from "react";
import Logo from "../../Assest/images/logo.gif";
import Googleicon from "../../Assest/images/google.png";
import Banner from "../../Assest/images/login.gif";
import { useDispatch } from "react-redux";
import { SetLoginModal, checklogin } from "../../Store/Reducer/config";
import { useGoogleLogin } from "@react-oauth/google";

const Login = () => {
  const Dispatch = useDispatch();
  const CloseLogin = () => {
    Dispatch(SetLoginModal(false));
  };
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.clear("DL_credential");
      localStorage.clear("DL_access_token");
      localStorage.setItem("DL_access_token", tokenResponse.access_token);
      fetch("https://www.googleapis.com/oauth2/v1/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((userObject) => {
          Dispatch(
            checklogin({
              is_login: true,
              name: userObject?.name,
              email: userObject?.email,
              profile: userObject?.picture,
            })
          );
          Dispatch(SetLoginModal(false));
        });
    },
  });
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#0009] z-[5] flex items-center justify-center">
      <div className="bg-DLlight flex items-start rounded-xl overflow-hidden relative max-[600px]:flex-col max-[600px]:w-[90%] max-h-[90vh]">
        <div className="w-[400px] max-[600px]:w-[100%]">
          <img src={Banner} alt="" />
        </div>
        <div className="bg-DLlight w-[500px] flex flex-col items-center p-5 max-[600px]:w-[100%]">
          <img src={Logo} alt="Logo" className="max-w-[200px]" />
          <h2 className="font-semibold text-[18px] my-2">
            Welcome to DLearners
          </h2>
          <p className="text-[12px] max-w-[300px] text-center text-[#0009] font-medium">
            Our mission is to Provide Seamless education opportunities for
            school children with specific Learning disabilities (SLD) through an
            assistive tech platform.
          </p>
          <button
            onClick={login}
            className="my-10 bg-[#000000] py-3 px-[50px] rounded-full text-DLlight font-semibold flex items-center"
          >
            <img src={Googleicon} alt="" className="w-[20px] mr-2" />{" "}
            <p className="text-[14px] max-[340px]:text-[12px]">
              Sign up with Google
            </p>
          </button>
        </div>

        <button
          className="absolute top-2 text-[22px] right-[15px]"
          onClick={CloseLogin}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
    </div>
  );
};

export default Login;
