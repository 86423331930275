import React from "react";
import { NavLink } from "react-router-dom";

const Header = ({ data }) => {
  return (
    <div
      className="w-full h-full px-6 py-12 text-center md:px-12 lg:text-left bg-[#0005]"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <div className="w-100 mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl ">
        <div className="grid items-center gap-12 lg:grid-cols-2">
          <div className="mt-12 lg:mt-0">
            <h1 className="mt-0 mb-10 text-5xl font-bold tracking-tight md:text-6xl xl:text-[62px] text-[hsl(218,81%,95%)] max-[600px]:text-[40px] max-[600px]:mb-2">
              {data?.title_1}
              <br />
              <p className="header-text">{data?.title_2}</p>
            </h1>
            <p className="text-[#fff] my-5 mb-10 max-[600px]:my-2 max-[600px]:text-[13px] max-[600px]:mb-5 font-semibold">
              {data?.description}
            </p>
            <NavLink
              className="mb-2 inline-block rounded bg-neutral-50 px-8 py-3 text-sm font-medium uppercase leading-normal text-neutral-800 shadow-[0_4px_9px_-4px_#cbcbcb] transition duration-150 ease-in-out hover:bg-neutral-100 hover:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:bg-neutral-100 focus:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:outline-none focus:ring-0 active:bg-neutral-200 active:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(251,251,251,0.3)] dark:hover:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:focus:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:active:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] md:mr-2 md:mb-0 max-[600px]:px-3 max-[600px]:py-2 max-[600px]:text-[10px] max-[600px]:mx-2 text-[16px] font-semibold"
              data-te-ripple-init
              data-te-ripple-color="light"
              to="/resource"
              role="button"
            >
              Start Your Journey
            </NavLink>
          </div>
          <div className="mb-12 lg:mb-0">
            <img
              src={data?.thumb}
              className="w-full rounded-lg shadow-lg dark:shadow-black/20"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
