import React from "react";
import "./style.css";
import Intro from "./Component/Intro";
import Info from "./Component/Info";
import Footer from "../../Component/Footer/Footer";
const Home = () => {
  return (
    <div>
      <Intro />
      <Info />
      <Footer />
    </div>
  );
};

export default Home;
