import React from "react";
import Banner2 from "../../../Assest/images/games/Raji's Expedition.png";
import { NavLink } from "react-router-dom";
const Headerthree = () => {
  return (
    <div className="relative overflow-hidden  w-[100%]">
      <div className="relative z-[0] py-[150px] lg:pt-[200px]">
        <img
          src={Banner2}
          alt="image"
          className="absolute top-0 left-0  h-full w-full object-cover object-center"
        />
        <div className="container mx-auto relative  z-[1]">
          <div className="-mx-4 flex flex-wrap p-10 ">
            <div className="w-full px-4 ">
              <div className="max-w-[500px] bg-[#ffe8c7] border-[13px] border-DLlight rounded-xl bg- py-10 px-5 xs:px-8 sm:p-12 md:p-[40px]">
                <span className="mb-3 block text-base font-semibold text-DLorg">
                  Where Play Inspires Brilliance
                </span>
                <h2 className="mb-5 text-xl font-bold leading-snug text-dark xs:text-2xl sm:text-3xl md:text-[35px] md:leading-snug">
                  Games That Teach and Thrill
                </h2>
                <p className="mb-8 text-base font-medium text-body-color lg:mb-10">
                  We're dedicated to making education an exhilarating
                  experience, ensuring that every click and tap brings smiles
                  and knowledge. Dive into our playful universe and let the
                  adventure of learning begin!
                </p>
                <div className="flex flex-wrap">
                  <NavLink
                    to="/resource"
                    className="mb-2 bg-DLblue py-3 px-4 text-base font-medium text-DLlight transition hover:bg-opacity-90 sm:px-6 rounded-md"
                  >
                    Discover More
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headerthree;
