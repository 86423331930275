import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Downloadbtn from "../../../Assest/images/download.png";
import Eyebtn from "../../../Assest/images/eye.png";
import { Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Bubble from "../../../Assest/images/bubble2.png";

import Ball from "../../Ball";
const Pws = ({ Data, Loading }) => {
  const { Y_value, is_login } = useSelector((state) => state.config);
  const Dispatch = useDispatch();
  const [PwsImages, setPwsImages] = useState([]);
  const ViewPws = (list) => {
    // if (is_login) {
    let Duplicate = list?.split(",");
    let PWSlist = [];
    for (let i = 0; i < Duplicate.length; i++) {
      PWSlist.push({ src: Duplicate[i] });
    }
    setPwsImages(() => PWSlist);
    // } else {
    //   Dispatch(SetLoginModal(true));
    // }
  };
  const DownloadPWS = (url) => {
    // if (is_login) {
    let A = document.createElement("a");
    A.href = url;
    A.setAttribute("target", "_blank");
    A.click();
    // } else {
    //   Dispatch(SetLoginModal(true));
    // }
  };
  if (Loading) {
    return (
      <>
        <div className="p-5 rounded-3xl card-holder grid grid-cols-4">
          {[...new Array(5)].map((item, index) => {
            return (
              <div className=" bg-DLlight p-2 m-2 max-w-[200px]" key={index}>
                <Skeleton.Image
                  active={true}
                  style={{ width: "285px", height: "200px" }}
                />

                <Skeleton.Input active={true} size={"small"} block={false} />
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        {Data?.length <= 0 ? (
          <div className="py-5 rounded-3xl card-holder">
            <p className="text-center text-DLlight uppercase font-semibold text-[22px]">
              No PWS at the moment, but our imagination is at work!
            </p>
            <Ball images={Bubble} idd="pwstext" is_circle={true} />
            <div id="pwstext"></div>
          </div>
        ) : (
          <div className="p-5 rounded-3xl card-holder grid min-[800px]:grid-cols-4 max-[799px]:grid-cols-3 max-[650px]:grid-cols-2 max-[500px]:grid-cols-1">
            {Data?.map((item, index) => {
              return (
                <div
                  className=" bg-DLlight p-2 m-2 max-w-[200px] rounded-lg"
                  key={index}
                >
                  <div className="relative w-full ">
                    <img
                      src={item?.image_url}
                      alt=""
                      className="w-full h-auto min-h-[190px]  object-contain border-b-2"
                      loading="lazy"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end">
                      <button
                        className="w-[30px] h-[30px] my-2"
                        title="View"
                        onClick={() => ViewPws(item?.image_list)}
                      >
                        <img src={Eyebtn} alt="" className="w-full h-full" />
                      </button>
                      <button
                        // href={item?.pdf}
                        onClick={() => DownloadPWS(item?.pdf)}
                        className="w-[30px] h-[30px] m-2 cursor-pointer"
                        title="Download"
                      >
                        <img
                          src={Downloadbtn}
                          alt=""
                          className="w-full h-full"
                        />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="my-1 font-semibold text-[16px]">
                      {item?.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <Lightbox
          open={PwsImages?.length > 0 ? true : false}
          close={() => setPwsImages([])}
          slides={PwsImages}
          styles={{ container: { background: "white" } }}
          plugins={[Counter]}
          counter={{
            container: {
              style: {
                top: "unset",
                bottom: 0,
                color: "#000",
                fontSize: "22px",
                fontWeight: "600",
                left: "50%",
                transform: "translateX(-50%)",
              },
            },
          }}
        />
      </>
    );
  }
};

export default Pws;
