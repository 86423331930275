import React, { useEffect, useState } from "react";
import "./style.css";
import Tab from "./Components/Tab";
import { useDispatch, useSelector } from "react-redux";
import Playbtn from "../../Assest/images/play.png";
import Videobtn from "../../Assest/images/video.png";
import Closebtn from "../../Assest/images/close.png";

import { SetLoginModal, SetYvalue } from "../../Store/Reducer/config";
import Search from "./Components/Search";
import Filterbtn from "./Components/Filterbtn";
import VideomodalPopup from "./Components/Videomodal";
import Video from "./Components/Video";
import Games from "./Components/Games";
import Pws from "./Components/Pws";
const About = () => {
  const { Y_value, is_login } = useSelector((state) => state.config);
  const Dispatch = useDispatch();
  const [ActiveTab, setActiveTab] = useState("Videos");
  const [VideoModal, setVideoModal] = useState({
    modal: false,
    url: "",
  });
  const [Iframemodal, setIframemodal] = useState({
    modal: false,
    url: "",
  });

  const [Couselist, setCouselist] = useState(null);
  const [ActiveLevel, setActiveLevel] = useState("fundamental");
  const [ActiveCourse, setActiveCourse] = useState(0);
  const [LessonData, setLessonData] = useState(null);
  const [Loading, setLoading] = useState(false);

  const TabHandler = (id) => {
    document.querySelector(`#${id}`).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const ScrollHandler = (e) => {
    Dispatch(SetYvalue(e.target.scrollTop));
    ["Videos", "Games", "Liveworksheets", "Physical_worksheets"].forEach(
      (item, index) => {
        if (
          document.querySelector(`#${item}`).offsetTop -
            e.target.scrollTop -
            200 <
            document.querySelector(`#${item}`).clientHeight &&
          document.querySelector(`#${item}`).offsetTop -
            e.target.scrollTop -
            200 <
            0
        ) {
          setActiveTab(item);
        }
      }
    );
  };
  const PlayHandler = (url) => {
    // if (is_login) {
    setVideoModal({
      modal: true,
      url: url,
    });
    // } else {
    //   Dispatch(SetLoginModal(true));
    // }
  };
  const CloseVideo_Handler = () => {
    setVideoModal({
      modal: false,
      url: "",
    });
  };
  const CloseIframe_Handler = () => {
    setIframemodal({
      modal: false,
      url: "",
    });
  };
  const Playgame = (url) => {
    // if (is_login) {
    setIframemodal({
      modal: true,
      url,
    });
    // } else {
    //   Dispatch(SetLoginModal(true));
    // }
  };
  const SetCourse = (value) => {
    setActiveLevel(value);
    setActiveCourse(0);
    GetCourseDetail(value, Couselist[value][0]?.id);
  };

  const LessonHandler = (index, id) => {
    setActiveCourse(index);
    GetCourseDetail(ActiveLevel, id);
  };
  async function Getlist() {
    const response = await fetch(
      "https://dlearners.in/enabler_portal/source/website/websitegame.php"
    );
    const List = await response.json();
    setCouselist(List);
    if (List[ActiveLevel][0]?.id) {
      GetCourseDetail(ActiveLevel, List[ActiveLevel][0]?.id);
    }
  }
  async function GetCourseDetail(level, id) {
    setLoading(true);
    const response = await fetch(
      `https://dlearners.in/enabler_portal/source/website/website_details.php?work_id=${id}&work_level=${
        level == "fundamental" ? 2 : 1
      }`
    );
    const List = await response.json();
    setLessonData(List);
    TabHandler("Videos");
    setLoading(false);
  }

  useEffect(() => {
    Getlist();
  }, []);

  return (
    <div className="w-full  min-h-[93.3vh] game-view">
      <Tab SetCourse={SetCourse} ActiveLevel={ActiveLevel} />
      <div
        className={`min-[800px]:flex items-start justify-end w-full min-[800px]:h-[${
          Y_value < 250 ? "73vh" : "79.8vh"
        }]`}
      >
        <div className="max-[800px]:flex max-[800px]:flex-col max-[800px]:items-center">
          {Couselist != null ? (
            <div
              className={`w-[22%] mx-[10px] min-[800px]:fixed  top-[${
                Y_value < 250 ? "160px" : "120px"
              }] left-0 search-bar search-bg h-full max-[800px]:w-[90%]`}
            >
              <Search
                ActiveCourse={ActiveCourse}
                Couselist={Couselist}
                ActiveLevel={ActiveLevel}
                LessonHandler={LessonHandler}
              />
            </div>
          ) : (
            ""
          )}

          <Filterbtn ActiveTab={ActiveTab} TabHandler={TabHandler} />
        </div>

        <div
          className={`w-[calc(100%-24%)] min-[800px]:mt-[${
            Y_value < 250 ? "150px" : "100px"
          }] max-h-[100%] overflow-y-auto  max-[800px]:w-[100%]`}
          id="list-container"
          onScroll={ScrollHandler}
        >
          <div className="p-4" id="Videos">
            <h2 className="font-semibold text-[26px] mb-3 pl-3">Video</h2>
            <Video
              LessonData={LessonData}
              Loading={Loading}
              PlayHandler={PlayHandler}
            />
          </div>
          <div className="p-4" id="Games">
            <h2 className="font-semibold text-[26px] mb-3 pl-3">Games</h2>
            <Games
              Data={LessonData?.games}
              Playgame={Playgame}
              Playbtn={Playbtn}
              Loading={Loading}
              type="Games"
            />
          </div>
          <div className="p-4" id="Liveworksheets">
            <h2 className="font-semibold text-[26px] mb-3 pl-3">
              Liveworksheets
            </h2>
            <Games
              Data={LessonData?.lws}
              Playgame={Playgame}
              Playbtn={Playbtn}
              Loading={Loading}
              type="LWS"
            />
          </div>
          <div className="p-4" id="Physical_worksheets">
            <h2 className="font-semibold text-[26px] mb-3 pl-3">
              Physical worksheets
            </h2>
            <Pws Data={LessonData?.pws} Loading={Loading} />
          </div>
        </div>
      </div>

      {VideoModal.modal && (
        <VideomodalPopup
          Videobtn={Videobtn}
          CloseVideo_Handler={CloseVideo_Handler}
          Closebtn={Closebtn}
          VideoModal={VideoModal}
        />
      )}

      {Iframemodal.modal && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#000] z-[3] flex items-center justify-center game-player-container">
          <div className="w-[80%] relative">
            <iframe
              className="iframe"
              src={Iframemodal?.url}
              frameBorder="0"
              scrolling="no"
            ></iframe>
            <button
              className="close-btn max-[700px]:left-0  max-[700px]:top-400"
              onClick={CloseIframe_Handler}
              title="close"
            >
              <img src={Closebtn} alt="" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
