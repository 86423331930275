import React from "react";
import PWS_Gif from "../../../Assest/images/pws.gif";
import Basic from "../../../Assest/images/basic.png";
import Fundamental from "../../../Assest/images/fundamental.png";
import Lws1 from "../../../Assest/images/LWS/1.png";
import Lws2 from "../../../Assest/images/LWS/2.png";
import Lws3 from "../../../Assest/images/LWS/3.png";
import Lws4 from "../../../Assest/images/LWS/4.png";
import Games1 from "../../../Assest/images/games/Alan_Park.png";
import Games2 from "../../../Assest/images/games/Fantastic_Five.png";
import Listbtn from "./list";
import { NavLink } from "react-router-dom";
const Info = () => {
  return (
    <>
      <div className="container  mx-auto">
        <div className="flex flex-wrap items-center mt-5">
          <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto mt-5 ">
            <img
              alt="..."
              src={Fundamental}
              className="rounded-lg overflow-hidden"
            />
            <h2 className="text-3xl mb-2 font-semibold leading-normal">
              Foundational Skills: <br /> (Fundamental Level)
            </h2>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
              When you're just starting out, we focus on the foundation. We
              teach you three essential skills: Reading, Writing, and
              Arithmetic. These are like the building blocks of learning. We
              also help you improve your ability to see and hear things clearly
              through fun activities.
            </p>
          </div>

          <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto mt-5">
            <img alt="..." src={Basic} className="rounded-lg overflow-hidden" />
            <h2 className="text-3xl mb-2 font-semibold leading-normal">
              Building on the Basics: <br /> (Basic Level)
            </h2>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
              As you move up, we take your learning to the next level. You'll
              start working on things like spelling, vocabulary, and forming
              sentences properly. Plus, we introduce you to "Functional
              Mathematics," which is like learning the language of math. It
              helps you understand and solve math problems better.
            </p>
          </div>

          <div className="border-b-2 border-DLorg w-[80%] mx-auto my-20"></div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="w-12/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto mt-5">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-pink-600">
              <img
                alt="..."
                src={Games2}
                className="rounded-lg overflow-hidden"
              />
              <blockquote className="relative p-8 mb-4 bg-DLblue">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  className="absolute left-0 w-full block"
                  style={{ height: "95px", top: "-94px" }}
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    className="text-DLblue fill-current"
                  ></polygon>
                </svg>
                <h4 className="text-xl font-bold text-white">
                  Enchanting Educational Videos for Kids
                </h4>
                <p className="text-md font-light mt-2 text-white">
                  Discover our delightful learning concept videos tailored for
                  children. These videos combine fun and education, offering
                  young minds a captivating journey through a world of
                  knowledge, creativity, and imagination.
                </p>
              </blockquote>
            </div>
          </div>
          <div className="w-full md:w-6/12 px-4">
            <div className="w-full md:w-10/12 px-12 md:px-4 ml-auto mr-auto mt-4">
              <NavLink
                to="/resource"
                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i className="fa-solid fa-video text-2xl"></i>
              </NavLink>
              <h2 className="text-3xl mb-2 font-semibold leading-normal">
                <NavLink to="/resource">
                  Explaining Concepts with Videos: (Concept Videos)
                </NavLink>
              </h2>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                We have cool videos that explain tricky ideas using pictures and
                sounds. These videos make learning more fun and easier to
                understand.
              </p>
              <Listbtn />
              <NavLink
                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                to="/resource"
              >
                View All{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto overflow-hidden pb-2">
        <div className="flex flex-wrap items-center my-10">
          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-10">
            <NavLink
              to="/resource"
              className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i className="fa-solid fa-gamepad text-2xl"></i>
            </NavLink>
            <h3 className="text-3xl mb-2 font-semibold leading-normal">
              <NavLink to="/resource"> Learning through Games: (Games)</NavLink>
            </h3>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
              Learning can be a lot of fun! We use games to help you practice
              what you've learned. You get to use your eyes, ears, hands, and
              even your whole body to learn new things.
            </p>
            <Listbtn />
            <NavLink
              className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
              to="/resource"
            >
              View All{" "}
              <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </NavLink>
          </div>
          <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-3">
            <div className="relative flex flex-col min-w-0 w-full mb-6 mt-4 md:mt-0">
              <img
                src={Games1}
                alt=""
                className="rounded-lg overflow-hidden shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center max-[600px]:flex-col-reverse pt-3">
          <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-3">
            <div className="justify-center flex flex-wrap relative">
              <div className="my-4 w-full lg:w-6/12 px-4">
                <img src={Lws1} alt="" className="my-5 shadow-lg" />
                <img src={Lws2} alt="" className="my-5 shadow-lg" />
              </div>
              <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                <img src={Lws3} alt="" className="my-5 shadow-xl" />
                <img src={Lws4} alt="" className="my-5 shadow-xl" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-4">
            <NavLink
              to="/resource"
              className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i className="fa-solid fa-puzzle-piece text-2xl"></i>
            </NavLink>
            <h3 className="text-3xl mb-2 font-semibold leading-normal">
              <NavLink to="/resource">
                Interactive Worksheets: <br /> (LWS - Live Work Sheets)
              </NavLink>
            </h3>
            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
              We have special worksheets that you can use on your computer or
              tablet. These worksheets are like games, but they also help you
              practice important skills.
            </p>

            <Listbtn />
            <NavLink
              className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
              to="/resource"
            >
              View All{" "}
              <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 pb-32 pt-4">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div className="md:pr-12">
              <NavLink
                to="/resource"
                className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i className="fas fa-file-alt text-xl"></i>
              </NavLink>
              <h3 className="text-3xl font-semibold">
                <NavLink to="/resource">
                  Paper Worksheets: <br /> (PWS - Physical Work Sheets)
                </NavLink>
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                Don't worry; we haven't forgotten about good old-fashioned paper
                and pencils. We also have worksheets that you can print out and
                write on. It's another way to practice what you've learned.
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                        <i className="fas fa-fingerprint"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-slate-500">
                        Built by Academic experts
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                        <i class="fa-solid fa-child-reaching"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-slate-500">
                        Expertly Crafted for Young Minds
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                        <i class="fa-brands fa-creative-commons-sampling"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-slate-500">
                        Dynamically designed for interactive learning.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
              <Listbtn />
              <NavLink
                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                to="/resource"
              >
                View All{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </NavLink>
            </div>
          </div>
          <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              className="max-w-full rounded-lg"
              style={{
                transform:
                  "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
              }}
              src={PWS_Gif}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
