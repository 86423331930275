import { Skeleton } from "antd";
import React from "react";
import Ball from "../../Ball";
import Bubble from "../../../Assest/images/box.png";
import Cat from "../../../Assest/images/cat.png";
import { useState } from "react";
const Games = ({ Data, Loading, Playgame, Playbtn, type }) => {
  const [Ismobile, setIsmobile] = useState(false);
  const CheckMobile = (url) => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsmobile(true);
    }
    Playgame(url);
  };
  if (Loading) {
    return (
      <>
        <div className="p-5 rounded-3xl card-holder min-[800px]:grid-cols-3  max-[800px]:grid-cols-2 max-[600px]:grid-cols-1">
          {[...new Array(5)].map((item, index) => {
            return (
              <div className=" bg-DLlight p-2 m-2 max-w-[300px]" key={index}>
                <Skeleton.Image
                  active={true}
                  style={{ width: "285px", height: "200px" }}
                />

                <Skeleton.Input active={true} size={"small"} block={false} />
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        {Ismobile && (
          <div className="fixed top-0 left-0 w-full h-full bg-[#0006] z-[5] flex items-center justify-center video-player-container">
            <div className="p-2 py-5 bg-DLlight rounded max-w-[300px]">
              <h2 className="text-[26px] my-2 text-[#e49a33] font-semibold">
                Alert
              </h2>
              <p className="font-semibold">
                For the best experience, please use a desktop or laptop
                computer.
              </p>

              <button
                onClick={() => setIsmobile(false)}
                className="bg-[tomato] px-3 py-1 text-DLlight mt-3 mx-auto block rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {Data?.length <= 0 ? (
          <div className="py-5 rounded-3xl card-holder">
            <p className="text-center text-DLlight uppercase font-semibold text-[22px]">
              {type != "LWS"
                ? "Looks like we're still crafting games for this part. Stay tuned!"
                : "Oops, no worksheets here, but we're busy making fun stuff!"}
            </p>
            <Ball
              images={type == "LWS" ? Cat : Bubble}
              idd={type + "1"}
              is_circle={true}
            />
            <div id={type + "1"}></div>
          </div>
        ) : (
          <div className="p-5 rounded-3xl card-holder grid min-[801px]:grid-cols-3  max-[800px]:grid-cols-2 max-[600px]:grid-cols-1">
            {Data?.map((item, index) => {
              return (
                <div
                  className=" bg-DLlight p-2 m-2 max-w-[300px] rounded-lg"
                  key={index}
                >
                  <div className="relative w-full">
                    <img
                      src={item?.image_url}
                      alt=""
                      className="w-full h-auto min-h-[180px] max-h-[200px] object-contain"
                      loading="lazy"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <button
                        className="w-[60px] h-[60px]"
                        title="Play"
                        onClick={() => {
                          // Playgame(item.play_url)
                          CheckMobile(item.play_url);
                        }}
                      >
                        <img
                          src={Playbtn}
                          alt=""
                          className="w-full h-full "
                          loading="lazy"
                        />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="my-1 font-semibold text-[16px]">
                      {item?.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
};

export default Games;
