import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Tab = ({ ActiveLevel, SetCourse }) => {
  const Y_value = useSelector((state) => state.config.Y_value);
  const Navigate = useNavigate();
  return (
    <div
      className={`w-full flex items-center justify-between z-[1] py-1 min-[800px]:fixed top-[50px]`}
    >
      <button
        onClick={() => Navigate("/")}
        className="text-[32px] text-DLdark pl-5"
        title="Back"
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>
      <div>
        <button
          className={`tab-btn py-2 px-10 font-semibold mx-3  text-DLlight ${
            Y_value < 250 ? "scroll" : ""
          } ${
            ActiveLevel == "fundamental" ? "active" : ""
          } max-[800px]:px-5 max-[800px]:text-[11px]`}
          onClick={() => SetCourse("fundamental")}
        >
          Fundamental
        </button>
        <button
          className={`tab-btn py-2 px-10 font-semibold mx-3  text-DLlight ${
            Y_value < 250 ? "scroll" : ""
          } ${
            ActiveLevel == "basic" ? "active" : ""
          } max-[800px]:px-5 max-[800px]:text-[11px]`}
          onClick={() => SetCourse("basic")}
        >
          Basic
        </button>

        {/* <button className="splash-btn" onClick={() => SetCourse("fundamental")}>
          <img
            src={Splash}
            alt=""
            className={`w-[220px] h-[${Y_value < 250 ? "100px" : "60px"}] ${
              ActiveLevel == "fundamental" ? "active" : ""
            }`}
          />
          <p className={`${Y_value < 250 ? "" : "active"}`}>Fundamental</p>
        </button>
        <button className="splash-btn" onClick={() => SetCourse("basic")}>
          <img
            src={Splash}
            alt=""
            className={`w-[220px] h-[${Y_value < 250 ? "100px" : "60px"}] ${
              ActiveLevel == "basic" ? "active" : ""
            }`}
          />
          <p className={`${Y_value < 250 ? "" : "active"}`}>Basic</p>
        </button> */}
      </div>
      <button className="text-[42px] text-DLdark opacity-0"></button>
    </div>
  );
};

export default Tab;
