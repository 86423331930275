import React from "react";
import Banner1 from "../../../Assest/images/games/Mushroom Jumper.png";
import Banner2 from "../../../Assest/images/games/Alan_Park.png";
import Banner3 from "../../../Assest/images/games/Fantastic_Five.png";
import { NavLink } from "react-router-dom";

const Headertwo = () => {
  return (
    <section className="p-5 w-[100%] bg-gradient-to-b from-[#e9c172] via-[#c0e9b6] to-[#45b5ff] my-auto h-[100%]">
      <div className="container mx-auto h-[100%] flex">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4  lg:w-8/12">
            <div className="relative mb-8 h-[370px] rounded-lg overflow-hidden md:h-[480px]">
              <img
                src={Banner1}
                alt="product"
                className="h-full w-full  object-cover object-center"
              />

              <div className="absolute top-0 left-0 flex h-full w-full items-center px-8 md:px-12 bg-[#0003]">
                <div className="max-w-[420px]">
                  <h3>
                    <h1 className="mb-5 block text-2xl font-bold text-DLlight hover:text-primary sm:text-4xl max-[350px]:text-[18px]">
                      Boosting Brilliance through Games!
                    </h1>
                  </h3>
                  <p className="mb-10 text-base font-medium text-DLlight max-[350px]:text-[12px]">
                    With a wide range of interactive activities, puzzles, and
                    challenges, your child can have a blast while developing
                    crucial skills in math, language, science, and more. Join us
                    in fostering a love for learning that will last a lifetime!
                  </p>
                  <NavLink
                    to="/resource"
                    className="inline-flex items-center justify-center rounded bg-primary py-[10px] px-8 text-center text-base font-semibold text-DLdark hover:bg-opacity-90 uppercase bg-DLlight"
                  >
                    Start Explore
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-4/12">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 md:w-1/2 lg:w-full">
                <div className="relative mb-8 h-[223px] rounded-lg overflow-hidden">
                  <img
                    src={Banner2}
                    alt="product"
                    className="h-full w-full object-cover object-center"
                  />

                  <div className="absolute top-0 left-0 flex h-full w-full items-end justify-end p-6 sm:p-9  bg-[#0005]">
                    <div className="max-w-[180px] text-right">
                      <h3>
                        <p className="mb-3 block text-lg font-bold text-DLlight hover:text-primary xs:text-xl sm:text-2xl lg:text-xl xl:text-2xl max-[350px]:text-[18px]">
                          Exploring the World with Games!
                        </p>
                      </h3>
                      <NavLink
                        to="/resource"
                        className="text-base font-semibold text-DLlight hover:text-primary"
                      >
                        View all
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/2 lg:w-full">
                <div className="relative mb-8 h-[223px] rounded-lg overflow-hidden">
                  <img
                    src={Banner3}
                    alt="product"
                    className="h-full w-full object-cover object-center"
                  />

                  <div className="absolute top-0 left-0 flex h-full w-full items-end justify-end p-6 sm:p-9 bg-[#0005]">
                    <div className="max-w-[180px] text-right">
                      <h3>
                        <p className="mb-3 block text-lg font-bold text-DLlight hover:text-primary xs:text-xl sm:text-2xl lg:text-xl xl:text-2xl max-[350px]:text-[18px]">
                          Play, Learn, and Grow Together!
                        </p>
                      </h3>
                      <NavLink
                        to="/resource"
                        className="text-base font-semibold text-DLlight hover:text-primary"
                      >
                        View all
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Headertwo;
