import React from "react";
import Playbtn from "../../../Assest/images/play.png";
import { Skeleton } from "antd";
import Ball from "../../Ball";
import Bubble from "../../../Assest/images/bubble1.png";
const Video = ({ LessonData, PlayHandler, Loading }) => {
  if (Loading) {
    return (
      <>
        <div className="p-5 rounded-3xl card-holder grid min-[800px]:grid-cols-3  max-[800px]:grid-cols-2 max-[600px]:grid-cols-1">
          {[...new Array(5)].map((item, index) => {
            return (
              <div className=" bg-DLlight p-2 m-2 max-w-[300px]" key={index}>
                <Skeleton.Image
                  active={true}
                  style={{ width: "285px", height: "200px" }}
                />

                <Skeleton.Input active={true} size={"small"} block={false} />
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        {LessonData?.video?.length <= 0 ? (
          <div className="py-5 rounded-3xl card-holder">
            <p className="text-center text-DLlight uppercase font-semibold text-[22px]">
              No videos yet, but we promise something cool is on its way!
            </p>
            <Ball images={Bubble} idd="text" is_circle={true} />
            <div id="text"></div>
          </div>
        ) : (
          <div className="p-5 rounded-3xl card-holder grid min-[800px]:grid-cols-3  max-[800px]:grid-cols-2 max-[600px]:grid-cols-1">
            {LessonData?.video?.length > 0
              ? LessonData?.video?.map((item, index) => {
                  return (
                    <div
                      className=" bg-DLlight p-2 m-2 max-w-[300px] rounded-lg"
                      key={index}
                    >
                      <div className="relative w-full">
                        <img
                          src={item?.thumbnail}
                          alt=""
                          className="w-full h-auto min-h-[180px] max-h-[200px] object-contain"
                          loading="lazy"
                        />

                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                          <button
                            className="w-[70px] h-[70px]"
                            title="Play"
                            onClick={() => PlayHandler(item?.location)}
                          >
                            <img
                              src={Playbtn}
                              alt=""
                              className="w-full h-full"
                              loading="lazy"
                            />
                          </button>
                        </div>
                      </div>
                      <div>
                        <p className="my-1 font-semibold text-[16px]">
                          {item?.name}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        )}
      </>
    );
  }
};

export default Video;
