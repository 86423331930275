import { useEffect } from "react";
import {
  Engine,
  Render,
  Runner,
  Bodies,
  World,
  Mouse,
  MouseConstraint,
  Composite,
  Composites,
} from "matter-js";

function Ball({ images, idd, is_circle }) {
  var render;
  function one() {
    // create engine
    if (document.querySelector(`#${idd}`)) {
      document.querySelector(`#${idd}`).innerHTML = "";
    }
    var engine = Engine.create(),
      world = engine.world;

    // create renderer
    render = Render.create({
      element: document.querySelector(`#${idd}`),
      engine: engine,
      options: {
        width: document.querySelector(".card-holder")?.clientWidth - 10,
        height: 200,
        wireframes: false,
        background: "transparent",
      },
    });
    Render.run(render);
    // create runner
    var runner = Runner.create();
    Runner.run(runner, engine);

    var stack = Composites.stack(
      100,
      600 - 21 - 20 * 20,
      is_circle ? 6 : 5,
      20,
      20,
      0,
      function (x, y) {
        if (is_circle) {
          return Bodies.circle(x, y, 80, {
            render: { sprite: { texture: images } },
          });
        } else {
          return Bodies.rectangle(x, y, 135, 135, {
            render: {
              strokeStyle: "#ffffff",
              sprite: {
                texture: images,
              },
            },
          });
        }
      }
    );
    Composite.add(world, [
      // walls
      // Bodies.rectangle(400, 0, 800, 50, { isStatic: true }),
      Bodies.rectangle(
        400,
        601,
        document.querySelector(".card-holder")?.clientWidth * 4.2,
        0.0001,
        {
          isStatic: true,
        }
      ),
      Bodies.rectangle(
        -document.querySelector(".card-holder")?.clientWidth * 0.86,
        250,
        50,
        850,
        {
          isStatic: true,
          render: {
            fillStyle: "transparent",
            strokeStyle: "transparent",
            lineWidth: 0,
          },
        }
      ),
      Bodies.rectangle(
        document.querySelector(".card-holder")?.clientWidth * 2.14,
        300,
        6,
        600,
        {
          isStatic: true,
          render: {
            fillStyle: "transparent",
            strokeStyle: "transparent",
            lineWidth: 0,
          },
        }
      ),
      stack,
    ]);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.05,
          render: {
            visible: false,
          },
        },
      });

    Composite.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: {
        x: document.querySelector(".card-holder")?.clientWidth * 1.3,
        y: 600,
      },
    });

    return {
      engine: engine,
      runner: runner,
      render: render,
      canvas: render.canvas,
      stop: function () {
        console.log("called");
        Render.stop(render);
        World.clear(engine.world);
        Engine.clear(engine);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
      },
    };
  }

  useEffect(() => {
    one();
    // context for MatterTools.Demo
    return () => {
      one().stop();
    };
  }, []);

  //   return <div className="" id={`${idd}`}></div>;
}

export default Ball;
