import React, { useEffect } from 'react'
import { googleLogout, useGoogleOneTapLogin } from '@react-oauth/google';
import { Provider } from './Navigation';
import Login from './Component/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { checklogin } from './Store/Reducer/config';
const App = () => {
  const { login_modal, is_login } = useSelector((state) => state.config);
  const Dispatch = useDispatch()


  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      localStorage.clear("DL_credential");
      localStorage.clear("DL_access_token");
      localStorage.setItem("DL_credential", credentialResponse.credential);
      const userObject = jwt_decode(credentialResponse.credential);
      Dispatch(checklogin({
        is_login: true,
        name: userObject?.name,
        email: userObject?.email,
        profile: userObject?.picture
      }))
    },
    onError: () => {
      console.log('Login Failed');
    },
  });


  useEffect(() => {
    let DL_credential = localStorage.getItem('DL_credential');
    let DL_access_token = localStorage.getItem('DL_access_token');

    if (DL_credential != null) {

      const userObject = jwt_decode(DL_credential);
      Dispatch(checklogin({
        is_login: true,
        name: userObject?.name,
        email: userObject?.email,
        profile: userObject?.picture
      }))
    }
    else if (DL_access_token != null) {
      fetch("https://www.googleapis.com/oauth2/v1/userinfo", {
        headers: {
          Authorization: `Bearer ${DL_access_token}`,
        },
      })
        .then((res) => res.json())
        .then((userObject) => {
          Dispatch(checklogin({
            is_login: true,
            name: userObject?.name,
            email: userObject?.email,
            profile: userObject?.picture
          }))
        });
    } else {
      Dispatch(checklogin({
        is_login: false,
        name: '',
        email: '',
        profile: ''
      }))
    }
    // Dispatch(checklogin());
  }, [])

  return (
    <div>

      {/* <button onClick={() => login()}>Logout</button> */}
      {Provider}
      {login_modal && <Login />}
      {/* <GoogleLogin
        onSuccess={credentialResponse => {
          console.log(credentialResponse);
        }}

        onError={() => {
          console.log('Login Failed');
        }}
        theme='outline'

        auto_select={true}
        useOneTap={true}
        cancel_on_tap_outside={false}
      /> */}
    </div>
  )
}

export default App