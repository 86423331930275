import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Navbar from "../Component/Navbar";
import Ball from "../Pages/Ball";

const Router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar />}>
      <Route path="*" element={<div>404 Not Found</div>} />
      <Route index element={<Home />} />
      <Route path="resource" element={<About />} />
      <Route path="degug" element={<Ball />} />
    </Route>
  )
);
export const Provider = <RouterProvider router={Router} />;
