import React from "react";
import { NavLink } from "react-router-dom";

const Listbtn = () => {
  return (
    <div className="block pb-6">
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Reading
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Mathematics
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Phonemic Awareness
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Sight words
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Vocabulary
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Vowels
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Writing
      </NavLink>
      <NavLink
        to="/resource"
        className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2"
      >
        Comprehension
      </NavLink>
    </div>
  );
};

export default Listbtn;
