import React from "react";

const Videomodal = ({ Videobtn, CloseVideo_Handler, Closebtn, VideoModal }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#fff9] z-[3] flex items-center justify-center video-player-container">
      <div className="video-player-bg">
        <img src={Videobtn} alt="" className="video-frame" />
        <video src={VideoModal?.url} controls></video>
        <button
          className={`close-btn top-[100%] bg-[#fff]`}
          onClick={CloseVideo_Handler}
          title="close"
        >
          <img src={Closebtn} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Videomodal;
